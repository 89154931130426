import { LazyLoadImage } from "react-lazy-load-image-component";
import { AboutJustintOne, AboutJustintTwo, SampleFile } from "../../../assets/images";

export default function AboutJustint() {
    return (
        <article className="w-100 flex flex-column items-center">
            <section className="about-justint">
                <article className="about-frame --left">
                    <LazyLoadImage
                        src={AboutJustintOne}
                        alt="about-justint"
                    />
                </article>
                <article className="about-card">
                    <article className="about-frame --right">
                        <LazyLoadImage
                            src={AboutJustintTwo}
                            alt="about-justint"
                        />
                    </article>
                    <h4>About Jus-Tint</h4>
                    <p>
                        We established our first workshop in Bukit Tinggi, Klang, and expanded with a new branch in Bandar Baru, Klang, in 2011.
                        <br /><br />Our team includes highly qualified Service Advisers, carefully chosen for their technical expertise and strong interpersonal skills. They are well-versed in every aspect of the service process, ensuring customer satisfaction remains our top priority.
                    </p>
                    <p>Founded in 2005</p>
                </article>
            </section>
        </article>
    );
}