import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { Icon } from "@iconify/react";
import AccessoriesFilter from "./accessories.filter";
import { getBrandList, getModelList, setSelectedCategory } from "../../../redux/reducer/commonReducer";
import { getCategoryList } from "../../../redux/reducer/commonReducer";
import { Skeleton } from "primereact/skeleton";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { getProductList } from "../../../redux/reducer/serviceReducer";

export default function AccessoriesHeader() {
    const dispatch = useDispatch()
    const { width } = useWindowDimensions()
    const [ search, setSearch ] = useState('')
    const { selectedCategory, categoryList, categoryLoading } = useSelector((state) => state.common);

    const handleSelectCategory = (category) => {
        if(category === 'all') {
            dispatch(setSelectedCategory([]));
        } else {
            if (selectedCategory.length > 0 && selectedCategory.includes(category)) {
                dispatch(setSelectedCategory(selectedCategory.filter(item => item !== category)));
            } else {
                dispatch(setSelectedCategory([...selectedCategory, category]));
            }
        }

        getFilterProductList()
    };

    const getFilterProductList = (clear) => {
        dispatch(getProductList({
            service: 'accessory',
            search: clear === 'clear' ? '' : search,
            category_id: selectedCategory,
        }))
    }

    useEffect(() => {
        dispatch(getBrandList())
        dispatch(getModelList())
        dispatch(getCategoryList())
    }, [])

    return (
        <section className="accessories-header">
            <article className="w-100">
                <h3>Latest Products</h3>
                <p>Jus-Tint are link with strong big company in the malaysia</p>
                <section className="accessories-search mt-5">
                    <AccessoriesFilter />
                    <input 
                        type="text"
                        value={search}
                        placeholder="Search by item name"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <article className="flex items-center gap-3">
                        <section 
                            className="clear-icon pointer" 
                            onClick={() => {
                                setSearch('')
                                getFilterProductList('clear')
                            }}
                        >
                            <Icon icon="iconamoon:close-duotone" />
                        </section>
                        <Button btnClassName="common-button search" onClick={() => getFilterProductList()}> 
                            <Icon icon="majesticons:search-line" className="--search" />
                            <p>Search</p>
                        </Button>
                    </article>
                </section>
                <hr />
                <section className="accessories-category">
                    <article 
                        className={`category-item ${selectedCategory.length === 0 ? '--active' : ''} pointer`} 
                        onClick={() => handleSelectCategory('all')}
                    >
                        <p>All</p>
                    </article>
                    {!categoryLoading ? 
                        categoryList?.map((category, categoryIndex) => (
                            <article 
                                key={categoryIndex} 
                                className={`category-item ${selectedCategory.length > 0 && selectedCategory.includes(category.id) ? '--active' : ''} pointer`} 
                                onClick={() => handleSelectCategory(category.id)}
                            >
                                <p>{category.name}</p>
                            </article>
                        ))
                    : 
                        Array.from({ length: width < 991 ? 4 : width < 550 ? 2 : 5 }, (_, categorySkeletonIndex) => (
                            <Skeleton
                                width="120px"
                                height="38px"
                                className="category-item"
                                key={categorySkeletonIndex}
                            />
                        ))
                    }
                </section>
            </article>
        </section>
    );
}

