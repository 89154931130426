import { useDispatch, useSelector } from "react-redux";
import { MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DefaultJustint, LargeDefaultJustint, SampleProduct1 } from "../../assets/images";
import ModalHeader from "../header/modal.header";
import FullModal from "../modal/full.modal.box";
import { closeAccessoriesModal, openLoginModal } from "../../redux/reducer/modalReducer";
import useWindowDimensions from "../hook/use.window.dimensions";
import { addCart, getCartInfo } from "../../redux/reducer/cartOrderReducer";
import { useToast } from "./common.toast";
import { useLocation } from "react-router-dom";
import { getCartCount } from "../../redux/reducer/commonReducer";

export default function AccessoriesModal() {
    const toast = useToast()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { width } = useWindowDimensions()

    const { token, username } = useSelector((state) => state.common);
    const { accessoriesInfo } = useSelector((state) => state.service);
    const { addCartLoading } = useSelector((state) => state.cart_order);
    const { isOpenAccessoriesModal } = useSelector((state) => state.modal);

    const productDetails = [
      // {
      //   label: 'Part Type',
      //   value: accessoriesInfo.category?.name
      // },
      {
        label: 'Brand',
        value: accessoriesInfo.brands
      },
      {
        label: 'Model',
        value: accessoriesInfo.car_models
      },
      {
        label: 'Year',
        value: accessoriesInfo.properties?.model_year
      },
    ]
  
    const handleCloseModal = () => {
      dispatch(closeAccessoriesModal())
    }

    const handleAddCart = (id) => {
      if(token && username) {
        dispatch(addCart({
          product_id: [id],
          quantity: 1
        }))
        .unwrap()
        .then((res) => {
          if(pathname === '/cart') {
            dispatch(getCartInfo())
          }
          handleCloseModal()
          dispatch(getCartCount())
          toast.success('Add to cart successfully.')
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        })
      } else {
        handleCloseModal()
        dispatch(openLoginModal())
      }
    } 

    return (
      <FullModal
        show={isOpenAccessoriesModal}
        backButton={handleCloseModal}
        screenSize={width >= 770 ? "xl" : "fullscreen-xl-down"}
        className="overflow-hidden"
        dialogClassName="width-500"
        contentClassName="br-34"
        content={
          <>
            <ModalHeader
              className="br-34 fixed"
              backTo={handleCloseModal}
              shareTo=""
              title="Product Details"
            />
            <MDBModalBody>
              <article className="accessories-modal">
                <section className="accessories-frame">
                  <LazyLoadImage
                    src={accessoriesInfo.media?.length > 0 ? accessoriesInfo.media[0].url : LargeDefaultJustint}
                    alt={accessoriesInfo.name?.name}
                  />
                </section>
                <section className="accessories-name-price">
                  <h6>{accessoriesInfo.name?.name}</h6>
                  <h5 className={isNaN(accessoriesInfo.price) ? 'transparent' : ''}>RM {isNaN(accessoriesInfo.price) ? 0 : parseFloat(accessoriesInfo.price).toLocaleString()}</h5>
                </section>
                <section className="accessories-flex">
                   <article className={`accessories-detail ${!accessoriesInfo.description || accessoriesInfo.description === "<p><br></p>" ? 'w-100' : ''}`}>
                      <h6 className="--title mb-2">Product Details</h6>
                      {productDetails.map((product, productIndex) => (
                        <article className="product-row" key={productIndex}>
                          <h6>{product.label}</h6>
                          {product.value && Array.isArray(product.value) ? 
                            <article className="flex flex-wrap justify-end gap-2">
                              {product.value?.length > 0 ? product.value.map((productValue, productValueIndex) => (
                                <p key={productValueIndex}>{productValue?.name || productValue}{productValueIndex + 1 !== product.value?.length ? <span>,</span> : ''}</p>
                               )) : <p>All</p>}
                            </article>
                          :
                            <p>{product.value}</p>
                          }
                        </article>
                      ))}
                   </article>
                   {accessoriesInfo.description && accessoriesInfo.description !== "<p><br></p>" &&
                    <article className="accessories-desc">
                        <h6 className="--title mb-2">Product Description</h6>
                        <hr />
                        <article className="desc" dangerouslySetInnerHTML={{ __html: accessoriesInfo.description }}></article>

                        {/* {productDetails.description.map((desc, descIndex) => (
                          <article className="desc-row mb-3" key={descIndex}>
                          </article>
                        ))} */}
                    </article>
                   }
                </section>
                {width < 500 && 
                  <section 
                    className={`modal-footer ${addCartLoading ? '--disabled' : ''} mobile`}
                    onClick={() => !addCartLoading ? handleAddCart(accessoriesInfo.id) : undefined}
                  >
                    <h5>{!isNaN(accessoriesInfo.price) ? 'Add To Cart' : 'View Price'}</h5>
                  </section>
                }
              </article>
            </MDBModalBody>
            {width > 500 && 
              <MDBModalFooter 
                className={`modal-footer ${addCartLoading ? '--disabled' : ''}`} 
                onClick={() => !addCartLoading ? handleAddCart(accessoriesInfo.id) : undefined}
              >
                <h5>{!isNaN(accessoriesInfo.price) ? 'Add To Cart' : 'View Price'}</h5>
              </MDBModalFooter>
            }
          </>
        }
      />
    );
  }
  