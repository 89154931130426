import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BandarBaruKlang, BandarBukitRaja, BandarBukitTinggi, ComingSoon, FooterLogo, Logo, MPV, MPVCar, NormalSaloon, NormalSaloonCar, SUV, SUVCar, SampleMission, SampleVision, SmallSaloon, SmallSaloonCar } from "../../assets/images";
import authService from "../../services/auth.service";
import apiService from "../../services/api.service";

export const login = createAsyncThunk('login', async (payload, thunkAPI) => {
  try {
    const response = await authService.login(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const sendOtp = createAsyncThunk('sendOtp', async (payload, thunkAPI) => {
  try {
    const response = await authService.sendOtp(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getCategoryList = createAsyncThunk('getCategoryList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCategoryList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getBrandList = createAsyncThunk('getBrandList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getBrandList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getModelList = createAsyncThunk('getModelList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getModelList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getCartCount = createAsyncThunk('getCartCount', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCartCount(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

const currentYear = new Date().getFullYear() 

export const initialData = {
  cartCount: 0,

  specializeList: [
    {
      label: "Tinted",
      navigate: "/service/car-tinted",
      icon: "game-icons:car-door",
    },
    {
      label: "PPF",
      navigate: "/service/car-ppf",
      icon: "mdi:papers",
      active_icon: "mdi:papers-outline"
    },
    {
      label: "Wrapping",
      navigate: "/service/car-wrapping",
      icon: "ion:car-sport-sharp",
      active_icon: "ion:car-sport-outline"
    },
    {
      label: "Coating",
      navigate: "/service/car-coating",
      icon: "ic:baseline-local-car-wash",
      active_icon: "ic:outline-local-car-wash"
    },
    {
      label: "Accessories",
      navigate: "/service/accessories",
      icon: "icon-park-solid:baby-car-seat",
      active_icon: "icon-park-twotone:baby-car-seat"
    },
    {
      label: "Windscreen",
      navigate: "/service/claim-windscreen",
      icon: "mdi:car-windshield-outline",
    },
    {
      label: "Ins/Renewal",
      navigate: "/service/insurance-renewal",
      icon: "mdi:shield-car",
    },
  ],
  threeBodyTypeList: [
    {
      label: "Standard",
      value: 'standard',
      description: "Proton/Perodua Car",
      image: NormalSaloon,
    },
    {
      label: "SUV",
      value: 'suv',
      description: "Sports Utility Vehicle",
      image: SUV,
    },
    {
      label: "MPV",
      value: 'mpv',
      description: "Multi-Purpose Vehicle",
      image: MPV,
    },
  ],
  twoBodyTypeList: [
    {
      label: "Standard",
      value: 'standard',
      description: "Sedan",
      image: NormalSaloon,
    },
    {
      label: "SUV / MPV",
      value: 'suv/mpv',
      description: "Large and Tall",
      image: MPV,
    },
  ],
  fourBodyTypeList: [
    {
      label: "Standard",
      value: 'standard',
      description: "Proton/Perodua Car",
      image: SmallSaloon,
      car_image: SmallSaloonCar,
    },
    {
      label: "Luxury",
      value: 'luxury',
      description: "High-end Levels Car",
      image: NormalSaloon,
      car_image: NormalSaloonCar,
    },
    {
      label: "SUV",
      value: 'suv',
      description: "Sports Utility Vehicle",
      image: SUV,
      car_image: SUVCar,
    },
    {
      label: "MPV",
      value: 'mpv',
      description: "Multi-Purpose Vehicle",
      image: MPV,
      car_image: MPVCar,
    },
  ],
  footerList: [
    {
      label: 'About Us',
      navigate: '/about-us'
    },
    {
      label: 'Tinted',
      navigate: '/service/car-tinted'
    },
    {
      label: 'PPF',
      navigate: '/service/car-ppf'
    },
    {
      label: 'Wrapping',
      navigate: '/service/car-wrapping',
      image: Logo
    },
    {
      label: 'Coating',
      navigate: '/service/car-coating'
    },
    {
      label: 'Windscreen',
      navigate: '/service/claim-windscreen'
    },
    {
      label: 'Insurance',
      navigate: '/service/insurance-renewal'
    }
  ],
  numberList: [
    {
      number: 5234,
      label: 'Modify Car',
      description: 'Of "high-performing" level are led by a certified project manager'
    },
    {
      number: 3400,
      label: 'Service Car',
      description: 'That meets quality standards required by our users'
    },
    {
      number: '24/7',
      label: 'Support',
      description: 'Actively engage team members that finishes on time'
    }
  ],
  visionMissionList: [
    {
      title: 'Our Vision',
      description: 'To be the <span>leading provider of premium automotive care</span>, known for <span>delivering exceptional quality, innovation, and customer satisfaction</span>.  We strive to set new standards in vehicle enhancement and protection.',
      image: SampleVision
    },
    {
      title: 'Our Mission',
      description: 'To provide <span>top-tier automotive services</span> that enhance and protect vehicles, ensuring long-term value for our customers. We are committed to using the <span>highest quality products and advanced techniques</span>.',
      image: SampleMission
    }
  ],
  locationList: [
    {
      name: 'HQ - Bandar Bukit Tinggi',
      address: 'No. 48 & 50, Lorong Batu Nilam 1b, Bukit Tinggi, 41200 Klang, Selangor Darul Ehsan, Malaysia.',
      phone_no: '03-3324 8068',
      image: BandarBukitTinggi
    },
    {
      name: 'Branch - Bandar Baru Klang',
      address: 'No. 27G, Jalan Tiara 2D/KU1, BBK Commercial Centre, Bandar Baru Klang, 41150 Klang, Selangor Darul Ehsan, Malaysia.',
      phone_no: '03-3324 2347',
      image: BandarBaruKlang
    },
    {
      name: 'Branch - Bandar Bukit Raja',
      address: 'No. 8 Jalan Astaka 4B/KU2, Bandar Bukit Raja, 41050 Klang, Selangor Darul Ehsan, Malaysia.',
      phone_no: '03-3324 2806',
      image: BandarBukitRaja
    },
    {
      name: 'Coming soon',
      address: 'Welcome contact for collabration',
      phone_no: null,
      image: ComingSoon
    }
  ],
  selectedBodyType: '',
  selectedCategory: [],
  selectedPackageOrAlacarte: 'alacarte',
  categoryList: [],
  brandList: [],
  modelList: [],
  filterBrand: [],
  filterModel: [],
  filterYear: [1990, currentYear],
  token: null,
  isTokenChecked: false,
  username: null,
  rank: null,
  role: null
};

const dataSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    getToken: (state) => {
      state.isTokenChecked = true;
      state.token = localStorage.getItem("token");
    },
    getUsername: (state) => {
      state.username = localStorage.getItem("username");
    },
    getUserRank: (state) => {
      state.rank = localStorage.getItem("rank");
    },
    getUserRole: (state) => {
      state.role = localStorage.getItem("role");
    },

    setSelectedBodyType: (state, action) => {
      state.selectedBodyType = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedPackageOrAlacarte: (state, action) => {
      state.selectedPackageOrAlacarte = action.payload;
    },

    setFilterBrand: (state, action) => {
      state.filterBrand = action.payload;
    },
    setFilterModel: (state, action) => {
      state.filterModel = action.payload;
    },
    setFilterYear: (state, action) => {
      state.filterYear = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(login.pending, (state) => {
      state.loginLoading = true;
    })
    .addCase(login.fulfilled, (state, action) => {
      state.loginLoading = false;
    })
    .addCase(login.rejected, (state) => {
      state.loginLoading = false;
    })

    .addCase(sendOtp.pending, (state) => {
      state.sendOtpLoading = true;
    })
    .addCase(sendOtp.fulfilled, (state, action) => {
      state.sendOtpLoading = false;
    })
    .addCase(sendOtp.rejected, (state) => {
      state.sendOtpLoading = false;
    })

    .addCase(getCategoryList.pending, (state) => {
      state.categoryLoading = true;
    })
    .addCase(getCategoryList.fulfilled, (state, action) => {
      state.categoryLoading = false;
      state.categoryList = action.payload.data.aaData
    })
    .addCase(getCategoryList.rejected, (state) => {
      state.categoryLoading = false;
    })

    .addCase(getBrandList.pending, (state) => {
      state.brandLoading = true;
    })
    .addCase(getBrandList.fulfilled, (state, action) => {
      state.brandLoading = false;
      state.brandList = action.payload.data.aaData
    })
    .addCase(getBrandList.rejected, (state) => {
      state.brandLoading = false;
    })

    .addCase(getModelList.pending, (state) => {
      state.modelLoading = true;
    })
    .addCase(getModelList.fulfilled, (state, action) => {
      state.modelLoading = false;

      const modelList = action.payload.data.aaData;
      const groupedByBrand = modelList.reduce((acc, model) => {
        const brand = model.brand_name || 'Others';
        if (!acc[brand]) {
          acc[brand] = [];
        }
        acc[brand].push(model);
        return acc;
      }, {});

      state.modelList = groupedByBrand
    })
    .addCase(getModelList.rejected, (state) => {
      state.modelLoading = false;
    })

    .addCase(getCartCount.pending, (state) => {
    })
    .addCase(getCartCount.fulfilled, (state, action) => {
      state.cartCount = action.payload.data
    })
    .addCase(getCartCount.rejected, (state) => {
    });
  }
});

export const { 
  getToken,
  getUsername,
  getUserRank,
  getUserRole,

  setSelectedCategory,
  setSelectedBodyType,
  setSelectedPackageOrAlacarte,

  setFilterBrand,
  setFilterModel,
  setFilterYear
} = dataSlice.actions;

export default dataSlice.reducer;

